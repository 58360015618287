var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "400",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("addComment")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "span",
                {
                  staticClass:
                    "mx-4 mt-4 col-10 px-0 grey--text text--darken-4 text-subtitle-1 text-truncate",
                },
                [_vm._v(_vm._s(_vm.$t("pleaseAddComments")) + " ")]
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-1 mb-2",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      rows: "2",
                      "error-messages": _vm.commentErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.comment.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.comment.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("comments")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: {
                            width: "90",
                            depressed: "",
                            disabled:
                              _vm.$v.$invalid || _vm.submitButtonClicked,
                          },
                          on: { click: _vm.commentTicketSubmit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mr-2 text-none rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                width: "50%",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none font-weight-regular grey--text rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                width: "50%",
                                depressed: "",
                                disabled:
                                  _vm.$v.$invalid || _vm.submitButtonClicked,
                              },
                              on: { click: _vm.commentTicketSubmit },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text text-none" },
                                [_vm._v(_vm._s(_vm.$t("submit")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }